<template>
  <div>
    <LandingNoLogin v-if="!isLoggedIn" />
    <LandingUser v-if="isLoggedIn && isUser" />
    <LandingCust v-if="isLoggedIn && !isUser" />
  </div>
</template>

<script>
import LandingNoLogin from '@/components/landing/NoLogin';

export default {
  name: 'AnuHome',
  computed: {
    isLoggedIn() {
      return this.$store.state.oLogin.isLoggedIn;
    },
    isUser() {
      return this.$store.state.oLogin.isUser;
    }
  },
  components: {
    LandingNoLogin,
    LandingUser: () => import('@/components/landing/User'),
    LandingCust: () => import('@/components/landing/Cust'),
  }
}
</script>
